import * as React from 'react';
import { LoginCallback } from '@okta/okta-react';
import { graphql } from 'gatsby';
import Loading from '../../components/common/loading/Loading';

const LoginCallbackPage = props => {
  // TODO supply an errorComponent
  const queryParams = new URLSearchParams(props.location.search);

  // Just in case the email is not assigned to the Public Secure account yet
  if (
    queryParams.get('error') === 'access_denied' &&
    queryParams.get('error_description') ===
      'User is not assigned to the client application.'
  ) {
    window.location.href =
      process.env.GATSBY_PUBLIC_SECURE_PROFILE +
      '?cid=' +
      process.env.GATSBY_PUBLIC_SECURE_CLIENT_ID;
    return <Loading />;
  }

  // IMPORTANT: Keep this conditional, or else the build will fail
  return LoginCallback ? <LoginCallback {...props} /> : <></>;
};

export default LoginCallbackPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
