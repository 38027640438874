import React from 'react';
import './Loading.scss';

type Props = {
  isBlockLoading?: boolean;
};

/**
 * Loading
 *
 * @param props
 * @constructor
 */
const Loading: React.FunctionComponent<Props> = props => {
  return (
    <div
      className={`mdmp__spinner-holder ${
        props.isBlockLoading ? 'mdmp__spinner-block-mode' : ''
      }`}
    >
      <div className="mdmp__spinner">
        <div className="mdmp__loader"></div>
        <span className="sr-only">Page Loading</span>
      </div>
    </div>
  );
};

export default Loading;
